// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap select  for live search on select
@import 'bootstrap-select/bootstrap-select.css';

// Bootstrap Date Picker
@import 'bootstrap-datepicker/bootstrap-datepicker.standalone.css';
